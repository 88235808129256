import React from 'react'
import { HiOutlineMenuAlt2, HiOutlineMenu } from 'react-icons/hi'
import { AiOutlineMenuFold } from 'react-icons/ai'
import { AiOutlineMenuUnfold } from 'react-icons/ai'
const NavToggle = ({ toggled, className }) => {
    return (
        <div className={className}>
            {toggled ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
        </div>
    )
}

export default NavToggle
