import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/api/login/authenticate/',
        method: 'post',
        data,
    })
}
export async function apiGetUser(data) {
    return ApiService.fetchData({
        url: '/api/user/',
        method: 'get',
        data,
    })
}
export async function apiGetLang(data) {
    return ApiService.fetchData({
        url: '/common/getDataParameter?sims_appl_code=Common&sims_appl_form_field=School%20Language',
        method: 'get',
        data,
    })
}
export async function apiGetUserDetails(data) {
    return ApiService.fetchData({
        url: '/common/Circular/getUserDetails?username=admin',
        method: 'get',
        data,
    })
}
export async function apiGetCompany(data) {
    return ApiService.fetchData({
        url: '/PDCSubmission/GetComp_Name?username=admin',
        method: 'get',
        data,
    })
}
export async function apiGetDepartmentName(data) {
    return ApiService.fetchData({
        url: '/EmployeeReport/GetDepartmentName',
        method: 'get',
        data,
    })
}

// export async function GetFinancialReport(data) {
//     return ApiService.fetchData({
//         url: '/PDCSubmission/GetFinancial_year?comp_code=1&username=admin',
//         method: 'get',
//         data,
//     })
// }
export async function apiGetFinancialYear(data) {
    return ApiService.fetchData({
        url: '/PDCSubmission/GetFinancial_year?comp_code=1&username=admin',
        method: 'get',
        data,
    })
}
export async function apiGetNavigation(data) {
    return ApiService.fetchData({
        url: '/common/getAllModules?username=admin',
        method: 'get',
        data,
    })
}
export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
