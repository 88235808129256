import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentschoolDetails: '',
    currentschoolParams: '',
    currentschoolFooter: '',
}

export const schoolDetailSlice = createSlice({
    name: 'base/schoolDetail',
    initialState,
    reducers: {
        setschoolDetails: (state, action) => {
            state.currentschoolDetails = action.payload
        },
        setSchoolParam: (state, action) => {
            state.currentschoolParams = action.payload
        },
        setSchoolFooter: (state, action) => {
            state.currentschoolFooter = action.payload
        },
    },
})

export const { setschoolDetails, setSchoolParam, setSchoolFooter } =
    schoolDetailSlice.actions

export default schoolDetailSlice.reducer
