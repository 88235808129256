const appConfig = {
    apiPrefix: 'https://moneta-api.mograerp.com/',
    authenticatedEntryPath: '/finance/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
