import { createSlice } from '@reduxjs/toolkit'

export const voucherSlice = createSlice({
    name: 'auth/voucher',
    initialState: {
        id: '',
        detail: {},
        businessUnit: [],
        permissions: [],
        voucher: [],
    },
    reducers: {
        setVoucherId: (state, action) => {
            state.id = action.payload
        },
        setVoucherDetail: (state, action) => {
            state.detail = action.payload
        },
        setBusinessUnit: (state, action) => {
            state.businessUnit = action.payload
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload
        },
        setLimitVoucher: (state, action) => {
            state.voucher = action.payload
        },
    },
})

export const {
    setVoucherId,
    setVoucherDetail,
    setBusinessUnit,
    setPermissions,
    setLimitVoucher,
} = voucherSlice.actions

export default voucherSlice.reducer
