import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import voucher from './voucherSlice'

const reducer = combineReducers({
    session,
    user,
    voucher,
})

export default reducer
