import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [],
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
        setLang: (state, action) => {
            state.token = action.payload
        },
        setUserDetails: (state, action) => {
            state.token = action.payload
        },
        setCompanyDetails: (state, action) => {
            state.token = action.payload
        },
        setDepartment: (state, action) => {
            state.token = action.payload
        },
        setFinancialYear: (state, action) => {
            state.token = action.payload
        },
        setNavigation: (state, action) => {
            state.token = action.payload
        },
    },
})

export const {
    setUser,
    setLang,
    setUserDetails,
    setCompanyDetails,
    setDepartment,
    setFinancialYear,
    setNavigation,
} = userSlice.actions

export default userSlice.reducer
