import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import schoolDetail from './schoolDetailSlice'

const reducer = combineReducers({
    common,
    schoolDetail,
})

export default reducer
